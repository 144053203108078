import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import ReCAPTCHA from "react-google-recaptcha";


function ProfileSignup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState([]);
  const [cv, setCv] = useState([]);
  const [res, setRes] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [avatar_img, setAvatar] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const handleSelectFile = (e) => setFile(e.target.files[0]);

  const [availability, setAvailability] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  const recaptchaRef = React.createRef();
  const handleStepOneSubmit = (event) => {
    event.preventDefault();
    if (passwordError || repeatPasswordError || password !== repeatPassword) {
      setRepeatPasswordError('Adgangskoderne stemmer ikke overens.');
      return;
    }
    setStepTwo(true);
  };

function returnStepOne() {
  setStepTwo(false);
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = recaptchaRef.current.getValue();
    console.log(token)
    if(!token){
      console.log("Jeg siger da lige hej her i loggen")
    }
    if (!token) {
      setErrorMessage("Vær venlig at udfylde reCAPTCHA.");
      return;
    }
    console.log(token)
    recaptchaRef.current.reset();

    try {
      setLoading(true);
      const data = new FormData();
      data.append("my_file", file);
      const res = await axios.post(`${process.env.REACT_APP_HOST}/api/uploadimage`, data);
      setRes(res.data);
      setAvatar(res.data.secure_url);
      try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/api/signup`,
        {
          email: email,
          fname: fname,
          lname: lname,
          location: location,
          password: password,
          skills: skills,
          avatar_img: res.data.secure_url,
          availability: availability,
          description: description,
          cv: cv,
          token: token
        }
      );
      if (response.data.success) {
        setSubmitSuccess(true);
        setErrorMessage('')
      } else if (response.data.captchasuccess===false) {
        setErrorMessage("Din reCAPTCHA verificering mislykkedes. Prøv at indlæs siden igen, og tryk på 'Jeg er ikke en robot' lige inden, du sender beskeden.");
      }
      else {
        setErrorMessage("Hov, der gik noget galt. Prøv igen.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Hov, der gik noget galt. Prøv igen.");
    }
    } catch (error) {
      console.error(error);
    }
  };

  const validatePassword = (password) => {
    setRepeatPassword(password);

    // Define the criteria for a strong password
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Check if the password meets the criteria
    if (password.length < minLength) {
      setPasswordError('Koden skal være over 8 karakterer.');
    } else if (!hasUpperCase) {
      setPasswordError('Koden skal indeholde mindst 1 stort bogstav.');
    } else if (!hasLowerCase) {
      setPasswordError(`Koden skal indeholde mindst 1 lille bogstav.`);
    } else if (!hasNumber) {
      setPasswordError('Koden skal indeholde mindst 1 tal.');
    } else if (!hasSpecialChar) {
      setPasswordError('Koden skal indeholde mindst 1 special karakter (f.eks. et af følgende: ",#,%,!,/").');
    } else {
      setPasswordError('');
    }
  };


  const validateRepeatPassword = (repeatPassword) => {
    setRepeatPassword(repeatPassword);
    if (password !== repeatPassword) {
      setRepeatPasswordError('Adgangskoderne stemmer ikke overens.');
    } else {
      setRepeatPasswordError('');
    }
  };

  return (
    <div>
      <Container className="">
      <div className={`row ${!submitSuccess ? 'underborder-black' : ''} mb-5`}>

          {!submitSuccess ? (<>
            <div className="col-md-12 ">
            <h1 className="mt-5">Opret profil</h1>
            <p className="lead">
              Opret en profil, fremhæv dine erfaringer og bliv hyret til
              filmproduktioner i Danmark
            </p>
          </div>
          </>) : 
          (<>

          </>)

          }

        </div>
        <div className="row mb-5">
        {!submitSuccess ? (
            <>
          {stepTwo == false ? (
            <>
              <p>
                <span class="badge bg-secondary">Trin 1/2</span>
              </p>
              <div className="col-md-6 mb-3 div-height-hundred-vh">
                <form onSubmit={handleStepOneSubmit}>
                  <div className="form-group mb-2">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="fname">Fornavn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      value={fname}
                      onChange={(event) => setFname(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="lname">Efternavn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      value={lname}
                      onChange={(event) => setLname(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="location">Område</label>
                    <select
                      className="form-select"
                      id="location"
                      defaultValue=""
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                      required
                    >
                      <option value="" disabled>
                        Vælg hvor du hører til
                      </option>
                      <option value="Hovedstaden">Hovedstaden</option>
                      <option value="Sjælland">Sjælland</option>
                      <option value="Syddanmark">Syddanmark</option>
                      <option value="Midtjylland">Midtjylland</option>
                      <option value="Nordjylland">Nordjylland</option>
                    </select>
                  </div>

                  <div className="form-group mb-2 mt-1">
                    <label htmlFor="file">
                      {" "}
                      <p>Upload et profilbillede </p>
                    </label>
                    <input
                      id="file"
                      type="file"
                      className="ms-2 signup-image-box"
                      onChange={handleSelectFile}
                      multiple={false}
                      required
                    />
                    <code>
                      {Object.keys(res).length > 0
                        ? Object.keys(res).map((key) => (
                            <p className="output-item" key={key}>
                              <span>{key}: </span>
                              <span>
                                {typeof res[key] === "object"
                                  ? "object"
                                  : res[key]}
                              </span>
                            </p>
                          ))
                        : null}
                    </code>
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor="password">Adgangskode</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="repeatPassword">Gentag adgangskode</label>
                    <input
                      type="password"
                      className="form-control"
                      id="repeatPassword"
                      value={repeatPassword}
                    onChange={(event) => validatePassword(event.target.value)}
          required
                    />
                  </div>
                  {passwordError && <div class="error alert alert-danger" role="alert">{passwordError}</div>}
                  {/* <p className="text-low-padding-bot">
                    I næste trin kan du tilføje en beskrivelse, angive hvilke roller du
                    kan udføre og vælge et profilbillede.{" "}
                  </p> */}

                  <button
                    type="submit"
                    className="button-search mt-3"
                  >
                    Fortsæt til trin 2
                  </button>
                </form>
                {message && <p>{message}</p>}
              </div>
            </>
          ) : (
            <>
              <p>
                <span class="badge bg-secondary">Trin 2/2</span>
              </p>
             
              <form onSubmit={handleSubmit}>
              <div className="row">
              <div className="col-md-6 mb-3 div-height-hundred-vh">
                
                  <div className="form-group mb-2 display-none">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      required
                    />
                  </div>
                  <div className="form-group mb-2 display-none">
                    <label htmlFor="fname">Fornavn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      value={fname}
                      required
                    />
                  </div>
                  <div className="form-group mb-2 display-none">
                    <label htmlFor="lname">Efternavn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      value={lname}
                      required
                    />
                  </div>
                  <div className="form-group mb-2 display-none">
                    <label htmlFor="location">Område</label>
                    <select
                      className="form-select"
                      id="location"
                      value={location}
                      required
                    >
                      <option value="" disabled>
                        Vælg hvor du hører til
                      </option>
                      <option value="Hovedstaden">Hovedstaden</option>
                      <option value="Sjælland">Sjælland</option>
                      <option value="Syddanmark">Syddanmark</option>
                      <option value="Midtjylland">Midtjylland</option>
                      <option value="Nordjylland">Nordjylland</option>
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor="description">Beskrivelse</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      rows={6}
                      cols={60}
                      required
                    />
                  </div>

                  <ReCAPTCHA 
                      sitekey={process.env.REACT_APP_SITE_KEY} 
                      ref={recaptchaRef}
                      />
                      {errorMessage && <p>{errorMessage}</p>}
                  <button type="submit" className="button-search mt-3" disabled={loading}>
  {loading ? 'Opretter profil...' : 'Opret profil'}
</button>
               
                {message && <p>{message}</p>}
              </div>
             
              <div className="col-md-6  mb-5">
                <div className="mb-3 form-check">
                  <label htmlFor="role" class="mb-2">
                    {" "}
                    Vælg op til 3 roller du kan udføre{" "}
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      name="Afspærring"
                      id="Afspærring"
                      checked={skills.includes("Afspærring") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Afspærring"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Afspærring"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Afspærring">
                      <span> Afspærring</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Animator"
                      id="Animator"
                      checked={skills.includes("Animator") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Animator"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Animator"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Animator">
                      <span> Animator</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Casting"
                      id="Casting"
                      checked={skills.includes("Casting") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Casting"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Casting"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Casting">
                      <span> Casting</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Colorgrader"
                      id="Colorgrader"
                      checked={skills.includes("Colorgrader") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Colorgrader"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Colorgrader"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Colorgrader">
                      <span> Colorgrader</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Computergrafik & -animation 3D"
                      id="Computergrafik & -animation 3D"
                      checked={
                        skills.includes("Computergrafik & -animation 3D")
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [
                                ...prevSkills,
                                "Computergrafik & -animation 3D",
                              ];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) =>
                                skill !== "Computergrafik & -animation 3D"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Computergrafik & -animation 3D">
                      <span> Computergrafik & -animation 3D</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Fotografassistent"
                      id="Fotografassistent"
                      checked={
                        skills.includes("Fotografassistent") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Fotografassistent"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Fotografassistent"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Fotografassistent">
                      <span> Fotografassistent</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Fotograf"
                      id="Fotograf"
                      checked={skills.includes("Fotograf") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Fotograf"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Fotograf"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Fotograf">
                      <span> Fotograf</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Grip"
                      id="Grip"
                      checked={skills.includes("Grip") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Grip"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Grip"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Grip">
                      <span> Grip</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Indspilningsleder"
                      id="Indspilningsleder"
                      checked={
                        skills.includes("Indspilningsleder") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Indspilningsleder"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Indspilningsleder"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Indspilningsleder">
                      <span> Indspilningsleder</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Instruktørassistent"
                      id="Instruktørassistent"
                      checked={
                        skills.includes("Instruktørassistent") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Instruktørassistent"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Instruktørassistent"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Instruktørassistent">
                      <span> Instruktørassistent</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Instruktør"
                      id="Instruktør"
                      checked={skills.includes("Instruktør") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Instruktør"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Instruktør"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Instruktør">
                      <span> Instruktør</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Klippeassistent"
                      id="Klippeassistent"
                      checked={
                        skills.includes("Klippeassistent") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Klippeassistent"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Klippeassistent"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Klippeassistent">
                      <span> Klippeassistent</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Klipper"
                      id="Klipper"
                      checked={skills.includes("Klipper") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Klipper"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Klipper"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Klipper">
                      <span> Klipper</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Komponist"
                      id="Komponist"
                      checked={skills.includes("Komponist") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Komponist"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Komponist"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Komponist">
                      <span> Komponist</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Konsulent"
                      id="Konsulent"
                      checked={skills.includes("Konsulent") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Konsulent"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Konsulent"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Konsulent">
                      <span> Konsulent</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Kostumier"
                      id="Kostumier"
                      checked={skills.includes("Kostumier") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Kostumier"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Kostumier"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Kostumier">
                      <span> Kostumier</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Location Manager"
                      id="Location Manager"
                      checked={
                        skills.includes("Location Manager") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Location Manager"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Location Manager"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Location Manager">
                      <span> Location Manager</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Manuskriptforfatter"
                      id="Manuskriptforfatter"
                      checked={
                        skills.includes("Manuskriptforfatter") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Manuskriptforfatter"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Manuskriptforfatter"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Manuskriptforfatter">
                      <span> Manuskriptforfatter</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Postproducer"
                      id="Postproducer"
                      checked={skills.includes("Postproducer") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Postproducer"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Postproducer"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Postproducer">
                      <span> Postproducer</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Producer"
                      id="Producer"
                      checked={skills.includes("Producer") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Producer"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Producer"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Producer">
                      <span> Producer</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Produktionsassistent"
                      id="Produktionsassistent"
                      checked={
                        skills.includes("Produktionsassistent") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Produktionsassistent"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Produktionsassistent"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Produktionsassistent">
                      <span> Produktionsassistent</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Produktionskoordinator"
                      id="Produktionskoordinator"
                      checked={
                        skills.includes("Produktionskoordinator") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Produktionskoordinator"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Produktionskoordinator"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Produktionskoordinator">
                      <span> Produktionskoordinator</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Produktionsleder"
                      id="Produktionsleder"
                      checked={
                        skills.includes("Produktionsleder") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Produktionsleder"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Produktionsleder"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Produktionsleder">
                      <span> Produktionsleder</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Regissør"
                      id="Regissør"
                      checked={skills.includes("Regissør") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Regissør"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Regissør"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Regissør">
                      <span> Regissør</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Rekvisitør"
                      id="Rekvisitør"
                      checked={skills.includes("Rekvisitør") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Rekvisitør"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Rekvisitør"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Rekvisitør">
                      <span> Rekvisitør</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Scenograf"
                      id="Scenograf"
                      checked={skills.includes("Scenograf") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Scenograf"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Scenograf"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Scenograf">
                      <span> Scenograf</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Set & -regibygger"
                      id="Set & -regibygger"
                      checked={
                        skills.includes("Set & -regibygger") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Set & -regibygger"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Set & -regibygger"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Set & -regibygger">
                      <span> Set & -regibygger</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Sminkør"
                      id="Sminkør"
                      checked={skills.includes("Sminkør") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Sminkør"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Sminkør"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Sminkør">
                      <span> Sminkør</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Speaker"
                      id="Speaker"
                      checked={skills.includes("Speaker") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Speaker"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Speaker"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Speaker">
                      <span> Speaker</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Statistkoordinator"
                      id="Statistkoordinator"
                      checked={
                        skills.includes("Statistkoordinator") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Statistkoordinator"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Statistkoordinator"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Statistkoordinator">
                      <span> Statistkoordinator</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Steadicamoperatør"
                      id="Steadicamoperatør"
                      checked={
                        skills.includes("Steadicamoperatør") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Steadicamoperatør"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Steadicamoperatør"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Steadicamoperatør">
                      <span> Steadicamoperatør</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Storyboardtegner"
                      id="Storyboardtegner"
                      checked={
                        skills.includes("Storyboardtegner") ? true : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Storyboardtegner"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Storyboardtegner"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Storyboardtegner">
                      <span> Storyboardtegner</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Toneassistent"
                      id="Toneassistent"
                      checked={skills.includes("Toneassistent") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Toneassistent"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Toneassistent"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Toneassistent">
                      <span> Toneassistent</span>{" "}
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="Tonemester"
                      id="Tonemester"
                      checked={skills.includes("Tonemester") ? true : false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSkills((prevSkills) => {
                          if (isChecked) {
                            if (prevSkills.length < 3) {
                              return [...prevSkills, "Tonemester"];
                            } else {
                              return prevSkills;
                            }
                          } else {
                            return prevSkills.filter(
                              (skill) => skill !== "Tonemester"
                            );
                          }
                        });
                      }}
                    ></input>
                    <label for="Tonemester">
                      <span> Tonemester</span>{" "}
                    </label>
                  </div>
                </div>
              </div>
             
              </div>
              </form> 
            </> )}    </>
          ) : (
            <>
            <div className="col-lg-6">
            <p className="mt-5">Din profil er blevet oprettet.</p><br></br>
            <p>Du modtager om et øjeblik en mail med et link, du skal bruge til at bekræfte din profil. Når du har gjort dette, vil din profil blive registreret.</p>
            </div>
            <div className="col-6"></div>
            </>
          )}
          
          </div>
      </Container>
    </div>
  );
}

export default ProfileSignup;
