import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

function ResetPasswordRequest() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const recaptchaRef = React.createRef();
  const [sitekey, setSitekey] = useState('');

  useEffect(() => {
    const siteKey = process.env.REACT_APP_SITE_KEY;
    console.log('RECAPTCHA_SITE_KEY:', siteKey);
    if (siteKey) {
      setSitekey(siteKey);
    } else {
      console.error('RECAPTCHA_SITE_KEY is not set');
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = recaptchaRef.current.getValue();
    if (!token) {
      setErrorMessage("Vær venlig at udfylde reCAPTCHA.");
      return;
    }
    recaptchaRef.current.reset();

    try {
      setLoading(true);
      const response = await axios.post(
        '/api/reset-password-mail',
        {
          email: email,
          token: token,
        },
        { withCredentials: true }
      );

      console.log(response.data);
      if (response.data.success) {
        setMessage('Link til nulstilling af adgangskode er sendt til din email.');
        setErrorMessage('');
      } else {
        setMessage('Hov, noget gik galt. Prøv igen.');
      }
    } catch (error) {
      console.error(error);
      setMessage('Der skete en fejl. Prøv igen senere.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Container>
        <div className="row underborder-black mb-5">
          <div className="col-md-12">
            <h1 className="mt-5">Lav en ny kode til din profil</h1>
            <p className="lead">
              Indtast din email og få tilsendt et link til at lave en ny kode
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6 div-height-hundred-vh">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </div>
              {sitekey ? (
                <ReCAPTCHA
                  sitekey={sitekey}
                  ref={recaptchaRef}
                />
              ) : (
                <p>Loading reCAPTCHA...</p>
              )}
              <button type="submit" className="button-search mt-3" disabled={loading}>
                {loading ? 'Sender link...' : 'Få tilsendt link'}
              </button>
              {message && <p className="mt-3">{message}</p>}
              {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ResetPasswordRequest;