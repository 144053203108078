import { useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';

function ProfileLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        '/api/login',
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      );

      console.log(response.data);
      if (response.data.success) {
        window.location.href = '/';
      } else {
        setMessage('Hov, noget gik galt. Prøv igen.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Container>
      <div className="row underborder-black mb-5 ">
          <div className="col-md-12 ">
            <h1 className="mt-5">Login</h1>
            <p className="lead">
              Login og få adgang til din profil
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6 div-height-hundred-vh">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Adgangskode</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              <button type="submit" className="button-search">
                Log ind
              </button>
              {message && <p className="text-danger">{message}</p>}
              <div className='mt-3'>
              <p>Glemt dit kodeord? <a href="/login/ny-kode">Lav et nyt her</a></p>
              </div>
              
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProfileLogin;